import React from "react"
import { fetchUser } from "../services"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { Container, Grid } from "@material-ui/core"
import { ExitToApp } from '@material-ui/icons';
import { signout } from "../utils"
import { useLocation } from "react-router";
import UpcomingPayouts from "../pages/upcoming-payouts"
import { useAuth } from "../utils/Auth"

export const Header = () => {
    const { user } = useAuth()

    // const { data: user, isUserLoading } = useQuery('user', () => fetchUser(), {
    //     select: res => res.data
    // })

    const location = useLocation()

    // if (isUserLoading) return <div>Loading...</div>

    if (user?.email == process.env.REACT_APP_FINANCE_ADMIN_EMAIL) {
        if (location.pathname !== "/upcoming-payouts" &&
            location.pathname !== "/withdrawal-requests" &&
            location.pathname !== "/acc-projects" &&
            !location.pathname.startsWith('/project-payments/')) {
            window.location.href = "/upcoming-payouts"
        }
    } else if (user?.email == process.env.REACT_APP_PERSONAL_SKILLS_EDITOR) {
        if (location.pathname !== "/unscorred-experts" &&
            !location.pathname.startsWith('/vetter-score-expert/') &&
            !location.pathname.startsWith('/vetter-application/')) {
            window.location.href = "/unscorred-experts"
        }
    } else if (user?.email == process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR) {
        if (location.pathname !== "/marketeer-experts" &&
            location.pathname !== "/marketeer-projects" &&
            location.pathname !== "/all-companies" &&
            !location.pathname.startsWith('/vetter-score-expert/') &&
            !location.pathname.startsWith('/vetter-application/')) {
            window.location.href = "/marketeer-experts"
        }
    }

    return (
        <div className="header is-flex aic">
            <Container maxWidth="lg">
                <Grid container direction="row" spacing={3}>
                    <div className="is-flex fdr aic jcsb has-full-width">
                        <div className="is-flex fdr aic">
                            {/* <Link to="/">
                                <img src={`${process.env.REACT_APP_STLLR_STATIC_URL}stllr-beta-icon.svg`} alt="Stllr.io" height="46" />
                            </Link> */}

                            {/* const isTechnicalEditable = user?.email === process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR; */}

                            {user?.email === process.env.REACT_APP_FINANCE_ADMIN_EMAIL ?
                                <>
                                    <a href="/acc-projects">Projects</a>
                                    <a href="/upcoming-payouts">Upcoming Payouts</a>
                                    <a href="/withdrawal-requests">Withdrawal Requests</a>
                                </>
                                : (user?.email === process.env.REACT_APP_PERSONAL_SKILLS_EDITOR ?
                                    <a href="/unscorred-experts">Experts</a>
                                    : (user?.email === process.env.REACT_APP_TECHNICAL_SKILLS_EDITOR ?
                                        <>
                                            <a href="/marketeer-projects">Projects</a>
                                            <a href="/marketeer-experts">Experts</a>
                                            <a href="/all-companies">Companies</a>
                                        </>
                                        :
                                        <>
                                            <a href="/">Experts</a>
                                            <a href="/ugc">UGC</a>
                                            <a href="/all-companies">Companies</a>
                                            <a href="/all-projects">Projects</a>
                                            <a href="/all-coupons">Coupons</a>
                                            <a href="/all-teams">Teams</a>
                                            <a href="/all-packages">Packages</a>
                                            <a href="/new-proposal">Proposal</a>
                                            {/* <a href="/all-groups">Groups</a> */}
                                            <a href="/chats">Chats</a>
                                            {/* <a href="/chats-admin">Chats Direct</a> */}
                                            <a href="/activities">Chat Activities</a>
                                            <a href="/upcoming-payouts">Upcoming Payouts</a>
                                            <a href="/withdrawal-requests">Withdrawal Requests</a>
                                            <a href="/page-activities">Page Activities</a>
                                            <a href="/demo-requests">Demo Requests</a>
                                        </>
                                    ))

                            }

                            {/* <a href="/">Experts</a>
                            <a href="/all-companies">Companies</a>
                            <a href="/all-projects">Projects</a>
                            <a href="/chats">Chats</a>
                            <a href="/upcoming-payouts">Upcoming Payouts</a>
                            <a href="/withdrawal-requests">Withdrawal Requests</a>
                            <a href="/activities">Chat Activities</a>*/}
                        </div>

                        <button
                            className="dimmed borderless transparent bold is-flex aic"
                            onClick={() => signout()}
                        >
                            <ExitToApp size={15} style={{ marginRight: 5 }} />
                            Logout
                        </button>
                    </div>
                </Grid>
            </Container>
        </div >
    )
}

export default Header