import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { fetchUser } from "../services/user"

const Auth = React.createContext()

const AuthProvider = props => {
    const [auth, setAuth] = useState(false)
    const [user, setUser] = useState({ isLoading: true })
    const token = Cookies.get("token")

    useEffect(() => {
        setAuth(token ? true : false)

        if (token) {
            // get user information
            fetchUser()
            .then(res => setUser(res.data))
            .catch(e => setUser({ error: true }))            
        }
    }, [token])

    if (token && user?.isLoading) {
        return 'loading..'
    }

    const authContextValue = { auth, setAuth, user }
    return <Auth.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(Auth);

export { AuthProvider, useAuth}