import React, { useState, useEffect } from "react"
import { CustomModal } from "./modal"
import { Button } from "./"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { useQuery, useMutation } from "react-query"
import Select from "react-select"
import { addProjectVideoDetails, submitVideoDetails } from "../services"

function FillVideoDetails({ isOpen, onClose, onSubmit, video, contentTypes, tones, languages, platforms, durations, formats, genders, ages, places }) {
    const { mutate: addVideoDetails, isLoading: isAdding } = useMutation(data => addProjectVideoDetails(data, video.id), {
        onSuccess: () => {
            submitVideo()
        },
        onError: error => {
            alert(error.data?.message)
        }
    })

    const { mutate: submitVideo, isLoading: isSubmittingVideo } = useMutation(data => submitVideoDetails({ mode: 'single' }, video.id), {
        onSuccess: () => {
            alert('Details added successfully')
            onSubmit()
        },
        onError: error => {
            alert(error.data?.message)
        }
    })
    return (
        <CustomModal
            open={isOpen}
            onClose={onClose}
            className="commonCustomModal feedbackModal"
            body={
                <Formik
                    initialValues={{
                        brief: {
                            name: video?.name || '',
                            instructions: video?.instructions || '',
                            content_type: video?.content_type || '',
                            tone_of_voice: video?.tone_of_voice || '',
                            language: video?.language || '',
                            platform: video?.platform ? video.platform.map(p => p.value) : [],
                            duration: video?.duration || '',
                            format: video?.format || '',
                            gender: (video?.type_id === 2 || video?.type_id === 3) ? video?.gender || '' : null,
                            age: video?.type_id === 3 ? video?.age || '' : null,
                            place: video?.type_id === 3 ? video?.place || '' : null,
                        },
                    }}
                    validationSchema={Yup.object().shape({
                        brief: Yup.object({
                            name: Yup.string().required('Video name is required'),
                            instructions: Yup.string().required('Video description is required'),
                            content_type: Yup.string().required('Content type is required'),
                            tone_of_voice: Yup.string().required('Tone is required'),
                            language: Yup.string().required('Language is required'),
                            platform: Yup.array().min(1, ('Platform is required')),
                        }),
                    })}
                    validateOnChange={false}
                    validateOnBlur={false} 
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        // Log full brief object
                        console.log('Brief object:', values.brief);
                        addVideoDetails(values.brief)
                    }}
                >
                    {({ setFieldValue, handleChange, errors, values }) => (
                        <Form className="form mt-30">
                            {/* Fields inside brief */}
                            <input
                                type="text"
                                name="brief.name"
                                value={values.brief.name}
                                onChange={(e) => setFieldValue('brief.name', e.target.value)}
                                className="stllr-input mb-10"
                                placeholder="Enter video title"
                            />
                            <textarea
                                name="brief.instructions"
                                value={values.brief.instructions}
                                onChange={(e) => setFieldValue('brief.instructions', e.target.value)}
                                className="stllr-input mb-10"
                                placeholder="Enter video description"
                            ></textarea>
                            <Select
                                name="brief.content_type"
                                options={contentTypes}
                                placeholder="Content type"
                                value={contentTypes.find(e => e.value === values.brief.content_type)}
                                onChange={({ value }) => setFieldValue('brief.content_type', value)}
                                className="mb-10"
                            />
                            <Select
                                name="brief.tone_of_voice"
                                options={tones}
                                placeholder="Tone of voice"
                                value={tones.find(e => e.value === values.brief.tone_of_voice)}
                                onChange={({ value }) => setFieldValue('brief.tone_of_voice', value)}
                                className="mb-10"
                            />
                            <Select
                                name="brief.language"
                                options={languages}
                                placeholder="Language"
                                value={languages.find(e => e.value === values.brief.language)}
                                onChange={({ value }) => setFieldValue('brief.language', value)}
                                className="mb-10"
                            />
                            <Select
                                name="brief.platform"
                                options={platforms}
                                placeholder="Platform"
                                value={platforms.filter(e => values.brief.platform.includes(e.value))}
                                onChange={(selectedOptions) => {
                                    const platformIds = selectedOptions.map(option => option.value);
                                    setFieldValue('brief.platform', platformIds);
                                }}
                                className="mb-10"
                                isMulti
                            />
                            <Select
                                name="brief.duration"
                                options={durations}
                                placeholder="Video duration"
                                value={durations.find(e => e.value === values.brief.duration)}
                                onChange={({ value }) => setFieldValue('brief.duration', value)}
                                className="mb-10"
                            />
                            <Select
                                name="brief.format"
                                options={formats}
                                placeholder="Video format"
                                value={formats.find(e => e.value === values.brief.format)}
                                onChange={({ value }) => setFieldValue('brief.format', value)}
                                className="mb-10"
                            />

                            {/* Conditional fields for video.type_id */}
                            {video.type_id === 3 && (
                                <>
                                    <Select
                                        name="brief.gender"
                                        options={genders}
                                        placeholder="Select gender"
                                        value={genders.find(e => e.value === values.brief.gender)}
                                        onChange={({ value }) => setFieldValue('brief.gender', value)}
                                        className="mb-10"
                                    />
                                    <Select
                                        name="brief.age"
                                        options={ages}
                                        placeholder="Select age"
                                        value={ages.find(e => e.value === values.brief.age)}
                                        onChange={({ value }) => setFieldValue('brief.age', value)}
                                        className="mb-10"
                                    />
                                    <Select
                                        name="brief.place"
                                        options={places}
                                        placeholder="Select place"
                                        value={places.find(e => e.value === values.brief.place)}
                                        onChange={({ value }) => setFieldValue('brief.place', value)}
                                        className="mb-10"
                                    />
                                </>
                            )}
                            {video.type_id === 2 && (
                                <Select
                                    name="brief.gender"
                                    options={genders}
                                    placeholder="Select gender"
                                    value={genders.find(e => e.value === values.brief.gender)}
                                    onChange={({ value }) => setFieldValue('brief.gender', value)}
                                    className="mb-10"
                                />
                            )}

                            <div className="is-flex aic">
                                <Button
                                    className="stllr-btn primary btn-md fw7"
                                    text="Submit"
                                    type="submit"
                                    disabled={isAdding || isSubmittingVideo}
                                />
                            </div>
                            {Object.keys(errors).length ? (
                                <span className="mt-10 v-error">
                                    Please check for wrong/missing fields [
                                    {Object.values(errors.brief).join(', ')}]
                                </span>
                            ) : ``}
                        </Form>
                    )}
                </Formik>
            }
        />
    );
}


export { FillVideoDetails }