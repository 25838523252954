import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { login } from "../services"
import { v4 as uuidv4 } from 'uuid'
import Cookies from "js-cookie"
import { useHistory } from "react-router-dom"
import { useAuth } from "../utils/Auth"
import { Grid } from "@material-ui/core"
import { Button } from "../components/button"

function Login (props) {
    const { setAuth } = useAuth()
    const history = useHistory()

    const processLogin = async (data) => {
            try {
            let res = await login({...data, uuid: uuidv4()})
            if (res.roles.some(role => [1, 2].includes(role))) {
                window.location.href = `${res.redirect_url}login?token=${res.accessToken}`
                return
            }
            Cookies.set("token", res.accessToken)
            setAuth(true)
            window.location.href = res.redirect_url
        } catch (e) {
            alert(e.message)
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item lg={4}>&nbsp;</Grid>

            <Grid item lg={4}>
                <div style={{height: "100vh", width: "100%"}} className="is-flex aic jcc">
                <Formik
                    initialValues={{email: "", password: ""}}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            processLogin(values)
                            setSubmitting(false)
                        }, 500)
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                        .email().required("Please enter a valid email address"),
                        password: Yup.string()
                        .required("No password provided")
                        .min(8, "Password has to be at least 8 characters")
                    })}
                >
                    {
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props

                            return (
                                <form onSubmit={handleSubmit} className="has-full-width">
                                    <div className="is-flex aife mb-55">
                                        <img src={`${process.env.REACT_APP_STLLR_STATIC_URL}stllr-beta-icon.svg`} alt="Stllr.io" height="100" />
                                        <span style={{fontSize: 20}}>Admin</span>
                                    </div>

                                    <div className="group">
                                        <label htmlFor="email">Your email address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter your email address"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`stllr-input`}
                                        />
                                        {errors.email && touched.email && (
                                            <span className="v-error">{errors.email}</span>
                                        )}
                                    </div>

                                    <div className="group">
                                        <label htmlFor="password">Your password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Enter your password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`stllr-input`}
                                        />
                                        {errors.password && touched.password && (
                                            <span className="v-error">{errors.password}</span>
                                        )}
                                    </div>

                                    <Button
                                        type="submit"
                                        className="stllr-btn btn-lg primary has-full-width"
                                        disabled={isSubmitting}
                                        text={"Login"}
                                    />
                                </form>
                            )
                        }
                    }
                </Formik>
                </div>
            </Grid>
        </Grid>
    )
}

export default Login